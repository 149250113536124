import { baseRequest } from './index';

/**
 * 首页商机信息
 * @returns BusInfo
*/
export const homeOppo = (aid) => baseRequest.post('/crmapp/homePage/count/oppo', {}, {
  headers: { aid }
});

/**
 * 首页跟进信息
 * @returns TrackInfo
*/
export const homeTrack = (aid) => baseRequest.post('/crmapp/homePage/count/track', {}, {
  headers: { aid }
});
