<template>
  <div class="home-box">
    <div class="home-head">
      <div class="home-header">
        <div class="home-title">
          <div class="cust-imgbox">
            <img
              src="@/assets/img/person.png"
              alt=""
            >
          </div>
          <div class="home-hello">
            <div class="home-name">
              <span>{{
                userInfo.userInfoRes && userInfo.userInfoRes.userName
              }}</span>
              <span>，欢迎你! 行动是成功的开始~</span>
            </div>
            <div class="home-time">
              <span>{{ getDate() }}</span>
              <span>{{ getWeek() }}</span>
              <span>
                <i>{{ `${numChangeChineses()}月` }}</i>
                <i class="weight">{{ `第${getMonthWeeks().getWeek}周` }}</i>
              </span>
            </div>
          </div>
        </div>
        <!--          @click="switchIdentity"-->
        <div
          v-if="!hideSwitchIdentity"
          class="home-icon"
          @click="showPopup"
        >
          <i class="icon-joyo cut">&#xe71c;</i>
        </div>
      </div>
      <div class="home-blank" />
      <div class="home-content">
        <div
          v-for="(item, index) of list"
          :key="index"
          class="home-main"
        >
          <div
            class="home-list-item"
            :style="{
              marginTop: index > 3 ? 10 / 16 + 'rem' : 20 / 16 + 'rem'
            }"
            @click="showDrawer(item)"
          >
            <div
              class="item-icon"
              :style="{ background: item.color }"
            >
              <i
                class="icon-joyo iconfont"
                :class="item.icon === '\ue734' ? 'marginL' : ''"
              >{{ item.icon }}</i>
            </div>
            <div class="item-title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="home-midmain"
    >
      <div class="home-publictitle">
        数据看板
      </div>
      <slide-view
        v-slot="{ data }"
        :slide-list="slideList.length ? slideList : slideDefaultList"
      >
        <div class="home-midcont">
          <div class="home-slide-title">
            <div class="home-slide-img">
              <img
                :src="data.image"
                :alt="data.image"
              >
            </div>
          </div>
          <div class="home-slide-cont">
            <div
              v-for="(item, index) of data.list"
              :key="index"
              class="home-slide-item"
            >
              <div class="home-slide-label">
                {{ item.label }}
              </div>
              <div class="home-slide-num">
                {{ item.num }}
              </div>
            </div>
          </div>
        </div>
      </slide-view>
    </div>
    <div
      class="home-bottom"
    >
      <div class="home-publictitle">
        快捷入口
      </div>
      <div class="home-midcont">
        <div
          v-for="(item, index) of quickEntry"
          :key="index"
          class="home-main"
        >
          <div
            class="home-list-item"
            @click="openPage(item.url)"
          >
            <div
              class="item-icon"
              :style="{ background: item.color }"
            >
              <i
                class="icon-joyo iconfont"
                :class="
                  item.icon === '\ue732' || item.icon === '\ue735'
                    ? 'marginL'
                    : ''
                "
              >{{ item.icon }}</i>
            </div>
            <div class="item-title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <cube-popup
      v-show="rolePopup"
      :mask="true"
      :center="false"
      :z-index="100"
      type="picker"
      @touchmove.prevent
      @mask-click="maskClick"
    >
      <transition name="cube-picker-move">
        <div
          v-show="rolePopup"
          class="role-wrapper"
        >
          <select-role
            ref="selectRoleRef"
            @hidePopup="hidePopup"
            @loadMenu="handleRole"
          />
        </div>
      </transition>
    </cube-popup>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';

import { homeOppo, homeTrack } from '_api/home';
import { getMenus } from '_api/sign-in';
import slideView from '_c/slide';

import {
  getMonthWeek,
  getWeekDate,
  getYearMonthDate,
  numChangeChinese
} from '@/lib/until';
import SelectRole from '@/views/home/select-role';

const list = [
  {
    icon: '\ue725',
    title: '线索',
    color: '#00A4FF',
    url: 'ClueManage',
    spaced: 'clue',
    access: false,
    openToUse: true,
    aidList: []
  },
  {
    icon: '\ue718',
    title: '客户',
    color: '#F86A5F',
    url: 'CustManage',
    spaced: 'cust',
    access: false,
    openToUse: true,
    aidList: []
  },
  {
    icon: '\ue720',
    title: '商机',
    color: '#F7B321',
    url: 'BusinessManage',
    spaced: 'business',
    access: false,
    openToUse: true,
    aidList: []
  },
  {
    icon: '\ue734',
    title: '跟进',
    color: '#00A4FF',
    url: 'FollowManage',
    spaced: 'follow',
    access: false,
    openToUse: true,
    aidList: []
  },
  {
    icon: '\ue71b',
    title: '签到',
    color: '#F7695F',
    spaced: 'sign',
    url: 'AddSigns',
    access: false,
    openToUse: true,
    aidList: []
  },
  {
    icon: '\ue71a',
    title: '联系人',
    color: '#F7B321',
    spaced: 'contacts',
    url: 'ContactsManage',
    access: false,
    openToUse: true,
    aidList: []
  },
  {
    icon: '\ue624',
    title: '工作报告',
    color: '#00A4FF',
    spaced: 'workreport',
    url: 'WorkreportManage',
    access: false,
    openToUse: true,
    aidList: []
  },
  {
    // icon: '\ue716',
    // title: '合同',
    // color: '#CCCCCC',
    // access: false,
    // openToUse: false,
    // aidList: []
  }
];
export default {
  components: {
    slideView,
    SelectRole
  },
  data() {
    return {
      list: JSON.parse(JSON.stringify(list)),
      quickEntry: [
        // {
        //   icon: '\ue723',
        //   title: '添加跟进',
        //   color: '#00A4FF',
        //   url: 'AddeditFollow'
        // },
        // {
        //   icon: '\ue727',
        //   title: '添加联系人',
        //   color: '#F7B321',
        //   url: 'addedit-contact'
        // },
        // {
        //   icon: '\ue729',
        //   title: '签到',
        //   color: '#F7695F',
        //   url: 'add-sign'
        // }
      ],
      slideDefaultList: [
        {
          title: '跟进',
          aid: '',
          image: require('@/assets/img/home-follow.png'),
          list: [
            {
              label: '总跟进量',
              num: '--'
            },
            {
              label: '本月跟进',
              num: '--'
            },
            {
              label: '本周跟进',
              num: '--'
            },
            {
              label: '今日跟进',
              num: '--'
            }
          ]
        },
        {
          title: '商机',
          aid: '',
          image: require('../../assets/img/home-bus.png'),
          list: [
            {
              label: '总商机量',
              num: '--'
            },
            {
              label: 'A类商机',
              num: '--'
            },
            {
              label: '本月新增',
              num: '--'
            },
            {
              label: '今日新增量',
              num: '--'
            }]
        }
      ],
      slideList: [
        // {
        //   title: '商机',
        //   aid: '',
        //   image: require('../../assets/img/home-bus.png'),
        //   list: []
        // },
        // {
        //   title: '跟进',
        //   aid: '',
        //   image: require('@/assets/img/home-follow.png'),
        //   list: []
        // }
      ],
      rolePopup: false
    };
  },
  computed: {
    hideSwitchIdentity() {
      const { orgAndPostList = [], roleViews = [] } = this.userInfo;
      return orgAndPostList.length === 1 && roleViews.length === 1;
    },
    ...mapGetters(['userInfo'])
  },
  mounted() {
    // console.log(this.userInfo.orgAndPostList[0].orgId);
    // this.numChangeChineses();
    this.handleRole();
  },
  methods: {
    ...mapMutations(['SET_KEEPALIVE']),
    ...mapActions(['getPeopleTree', 'departTree', 'getdictList', 'getOrgAlls']),
    openPage(url) {
      this.$router.push({ name: url, query: { type: 2 }});
    },
    showDrawer(item) {
      if (item.openToUse) {
        if (item.access) {
          this.$store.commit(`${item.spaced}/AID_LIST`, item.aidList);
          this.$router.push({ name: item.url, query: { type: 1 }});
          this.SET_KEEPALIVE([]);
        } else {
          this.$showToast('暂无访问权限');
        }
      }
      // else {
      //   this.dialog = this.$createDialog(
      //     {
      //       type: 'alert',
      //       showClose: true,
      //       confirmBtn: {
      //         text: '我知道了',
      //         active: true
      //       }
      //     },
      //     createElement => [
      //       createElement(
      //         'div',
      //         {
      //           class: {
      //             'my-title': true
      //           },
      //           slot: 'title'
      //         },
      //         [
      //           createElement('div', {
      //             class: {
      //               'my-title-img': true
      //             }
      //           })
      //           // createElement('p', '正在开发中')
      //         ]
      //       ),
      //       createElement(
      //         'div',
      //         {
      //           class: {
      //             'my-content': true
      //           },
      //           slot: 'content'
      //         },
      //         '正在开发中'
      //       ),
      //       createElement(
      //         'div',
      //         {
      //           class: {
      //             'my-contents': true
      //           },
      //           slot: 'content'
      //         },
      //         '程序猿正在加班赶需求，敬请期待哦~'
      //       )
      //     ]
      //   );
      //   this.dialog.show();
      // }
    },
    switchIdentity() {
      this.$router.push({ name: 'select-identity' });
    },
    getHomeOppo(aid) {
      homeOppo(aid).then(res => {
        if (res && res.flag) {
          this.slideList.push({
            image: require('../../assets/img/home-bus.png'),
            list: [
              {
                label: '总商机量',
                num: res.data.totalCount
              },
              {
                label: 'A类商机',
                num: res.data.levelOfACount
              },
              {
                label: '本月新增',
                num: res.data.monthNewIncremental
              },
              {
                label: '今日新增量',
                num: res.data.todayNewIncremental
              }
            ]
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getHomeTrack(aid) {
      homeTrack(aid).then(res => {
        if (res && res.flag) {
          this.slideList.push({
            image: require('@/assets/img/home-follow.png'),
            list: [
              {
                label: '总跟进量',
                num: res.data.totalCount
              },
              {
                label: '本月跟进',
                num: res.data.monthCount
              },
              {
                label: '本周跟进',
                num: res.data.weekCount
              },
              {
                label: '今日跟进',
                num: res.data.dayCount
              }
            ]
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    resetPage() {
      this.list = JSON.parse(JSON.stringify(list));
      this.quickEntry = [];
      this.slideList = [];
    },
    getMenusList() {
      // 每次获取菜单之前重置下
      this.resetPage()
      getMenus({}).then(res => {
        if (res.flag && res.data.length) {
          const menusList = res.data.filter(
            item => item.menuCode === 'crmmobile'
          )[0];
          menusList.children.forEach(itemA => {
            switch (itemA.mlabel) {
              case '数据看板':
                itemA.children.forEach(itemK => {
                  switch (itemK.mlabel) {
                    case '商机':
                      this.getHomeOppo(itemK.actions[0].aid);
                      break;
                    case '跟进':
                      this.getHomeTrack(itemK.actions[0].aid);
                      break;
                  }
                });
                break;
              case '添加联系人':
                this.quickEntry.push({
                  icon: '\ue732',
                  title: '添加联系人',
                  color: '#F7B321',
                  url: 'addedit-contact'
                });
                break;
              case '拜访签到':
                this.quickEntry.push({
                  icon: '\ue733',
                  title: '添加签到',
                  color: '#F7695F',
                  url: 'AddSigns'
                });
                break;
              case '添加跟进':
                this.quickEntry.push({
                  icon: '\ue735',
                  title: '添加跟进',
                  color: '#00A4FF',
                  url: 'AddeditFollow'
                });
                break;
            }
            this.list.forEach(itemL => {
              if (itemA.mlabel === itemL.title) {
                itemA.children.forEach(itemB => {
                  itemB.actions.forEach(itemC => {
                    if (itemC.acode === 'query') {
                      itemL.access = true;
                      itemL.aidList.push(itemB);
                    }
                  });
                });
              }
            });
          });
        } else {
          this.resetPage();
        }
      });
    },
    getWeek() {
      return getWeekDate();
    },
    getDate() {
      return getYearMonthDate().Y + getYearMonthDate().M + getYearMonthDate().D;
    },
    getMonthWeeks() {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      return getMonthWeek(year, month, day);
    },
    numChangeChineses() {
      const monthNum = this.getMonthWeeks().getMonth;
      return numChangeChinese(monthNum);
    },
    showPopup() {
      setTimeout(() => {
        this.rolePopup = true;
        this.$refs.selectRoleRef.showDrawer();
      }, 200);
    },
    hidePopup() {
      this.rolePopup = false;
    },
    maskClick() {
      this.hidePopup();
    },
    handleRole() {
      // 获取人员树
      this.getPeopleTree();
      // 获取部门树
      this.departTree(1);
      // 获取字典
      this.getdictList();
      // 获取所属体系
      this.getOrgAlls(this.userInfo.postRoleResList[0].orgId);
      // 获取菜单
      this.getMenusList();
      // console.log(window.b.c);
    }
  }
};
</script>

<style lang="scss">
.home-box{
  background-color: $color-FFF;
}
.home-head {
  position: relative;
  width: 100%;
  height: 285px;
  .home-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    color: $color-FFF;
    .cut {
      font-size: 20px;
    }
  }
  .home-header {
    width: 100%;
    height: 140px;
    overflow: hidden;
    background: linear-gradient(0deg, #019dee, #0090ff);
    .home-title {
      display: flex;
      justify-content: flex-start;
      height: 50px;
      margin: 20px 15px 20px 15px;
      .cust-imgbox {
        width: 50px;
        height: 50px;
        margin-right: 14.5px;
        background-color: #fff;
        border-radius: 50%;
        img {
          width: 50px;
          border-radius: 50%;
        }
      }
      .home-hello {
        width: 100%;
        color: $color-FFF;
        text-align: left;
        .home-name {
          margin: 6.5px 0 10px 0;
          font-size: $font-15;
        }
        .home-time {
          display: flex;
          justify-content: space-between;
          margin-right: 40px;
          font-size: $font-14;
          .weight {
            font-weight: 600;
          }
        }
      }
    }
  }
  .home-blank {
    width: 100%;
    height: 145px;
    background-color: $color-FFF;
  }
  .home-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 181px;
    margin: -194.5px 15px 0 15px;
    overflow: hidden;
    background-color: $color-FFF;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.15);
  }
}

.home-main {
  flex: 1 0 25%;
  height: 91px;
  .home-list-item {
    margin-top: 20px;
    overflow: hidden;
    .item-icon {
      width: 35px;
      height: 35px;
      margin: 0 auto;
      margin-bottom: 10px;
      line-height: 36px;
      text-align: center;
      border-radius: 8px;
      .iconfont {
        color: $color-FFF;
        font-size: 20px;
      }
    }
    .item-title {
      color: $color-666;
      font-size: $font-14;
    }
  }
}

.home-frame {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(92, 92, 92, 0.15);
}

.home-midmain {
  .home-midcont {
    display: flex;
    justify-content: flex-start;
    height: 145px;
    margin: 4px 15px;
    overflow: hidden;
    @extend .home-frame;
    .home-slide-title {
      width: 118px;
      height: 100px;
      margin-top: 22px;
      border-right: 1px solid #efefef;
      .home-slide-img {
        width: 75px;
        height: 61px;
        margin: 17px 0 0 21px;
        img {
          width: 75px;
        }
      }
    }
    .home-slide-cont {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20.5px;
      .home-slide-item {
        flex: 1 0 50%;
        .home-slide-label {
          margin-bottom: 9.5px;
          color: $color-999;
          font-size: $font-14;
        }
        .home-slide-num {
          color: $color-333;
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }
}

.slide-main {
  height: 164px;
  background-color: $color-FFF;
}

.home-publictitle {
  height: 45px;
  margin: 0 15px;
  color: $color-333;
  font-weight: 600;
  font-size: $font-16;
  line-height: 45px;
  text-align: left;
}
.home-bottom {
  padding-bottom: 20px;
  .home-midcont {
    display: flex;
    justify-content: flex-start;
    height: 100px;
    margin: 0 15px;
    @extend .home-frame;
    .home-main {
      flex: 0 0 25%;
      height: 91px;
    }
  }
}
.marginL {
  margin-left: 4px;
}

.role-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40vh;
  background: #fff;
  border-radius: 5px 5px 0 0;
}
.cube-picker-move-enter,
.cube-picker-move-leave-active {
  transform: translate3d(0, 100%, 0);
}

.cube-picker-move-enter-active,
.cube-picker-move-leave-active {
  transition: transform 0.2s ease-in-out 0.2s;
}
</style>
