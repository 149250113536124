import { render, staticRenderFns } from "./select-role.vue?vue&type=template&id=06f73ff8&scoped=true"
import script from "./select-role.vue?vue&type=script&lang=js"
export * from "./select-role.vue?vue&type=script&lang=js"
import style1 from "./select-role.vue?vue&type=style&index=1&id=06f73ff8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f73ff8",
  null
  
)

export default component.exports