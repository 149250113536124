<template>
  <div class="role-drawer">
    <cube-drawer
      ref="drawer"
      :data="data"
      :visible="false"
      :selected-index="[...selectedIndex]"
      @select="selectHandler"
    >
      <!--      style="height: auto"-->
      <cube-drawer-panel
        v-for="(panel, index) in data"
        :key="index"
        :index="index"
        :data="panel"
      >
        <cube-drawer-item
          v-for="(item, i) in panel"
          :key="i"
          :index="i"
          :item="item"
        >
          <div class="post-box">
            <div class="post-box-out">
              <div class="post-avatar">
                <div class="post-avatar-name">
                  {{ interceptNames(item.text) }}
                </div>
              </div>
              <div class="post-info-box">
                <div class="post-info-title-box">
                  <div class="post-info-title">
                    {{ item.postTitle }}
                  </div>
                  <div
                    v-if="postIndex === i"
                    class="post-current"
                  >当前岗位</div>
                </div>
                <div class="post-info-position">
                  {{ `所在部门：${item.orgTitle}` }}
                </div>
                <div class="post-info-position">
                  {{ `角色权限：${item.roleList ? item.roleList.map(item => item.roleLabel).join('、') : '暂无'}` }}
                </div>
              </div>
            </div>
          </div>
        </cube-drawer-item>
      </cube-drawer-panel>
    </cube-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { changeOrgRole } from '../../api/sign-in';
import config from '../../lib/config';
export default {
  name: 'SelectRole',
  data() {
    return {
      data: [[]],
      selectedIndex: [null],
      postId: '',
      postIndex: null,
      roleIndex: null,
      visible: false,
      adminUid: localStorage.getItem('USERNAME')
        ? JSON.parse(atob(localStorage.getItem('USERNAME')))
        : null,
      pageIndex: 0
    };
  },
  computed: {
    ...mapGetters(['orgAndPostList'])
  },
  created() {
    this.handleData();
  },
  mounted() {
    this.handleSelectedIndex();
  },
  methods: {
    interceptNames(val) {
      return val.substring(0, 2);
    },
    formatText(text = '') {
      // if (text.length <= 7) {
      // } else {
      //   return text.slice(0, 3) + '...' + text.slice(-4);
      // }
      return text;
    },
    handleSelectedIndex() {
      this.orgAndPostList.forEach((item, index) => {
        if (item.defaultFlag === 1) {
          this.selectedIndex[0] = index;
          this.postIndex = index;
          // (item.roleList || []).forEach((item, i) => {
          //   if (item.defaultFlag === 1) {
          //     this.selectedIndex[1] = i;
          //     this.roleIndex = i;
          //   }
          // });
        }
      });
    },
    handleData() {
      this.data[0] = this.orgAndPostList.map(item => {
        item.value = item.postId;
        item.text = item.postTitle;
        return item;
      });
    },
    showDrawer() {
      this.$refs.drawer.show();
      this.$refs.drawer.refill(0, this.data[0]);
    },
    selectHandler(selectedVal, indexList) {
      const [postId, roleId] = selectedVal;
      this.postIndex = indexList[0];
      this.selectedIndex = [...indexList];

      this.selectRole(postId, roleId);
    },
    selectRole(postId = this.postId, roleId) {
      const { orgId } = this.orgAndPostList.find(
        item => item.postId === postId
      );
      changeOrgRole({
        orgId,
        postId,
        roleId,
        loginSource: this.adminUid.loginSource
      }).then(res => {
        if (res.flag) {
          localStorage.setItem(
            'USERNAME',
            btoa(
              JSON.stringify({
                loginSource: this.adminUid.loginSource
              })
            )
          );
          this.setToken(res.data.token);
          this.setOrgAndPostList(res.data.postRoleResList);
          this.setUserInfo(res.data);
          const loadMenu = 'loadMenu';
          this.$emit(loadMenu);
          this.$showToast(res.errorMsg);
          const hidePopup = 'hidePopup';
          this.$emit(hidePopup);
        } else {
          if (res.data !== null) {
            const data = {
              account: res.data.userAccount,
              pwd: res.data.passWord,
              tenancyId: res.data.tenancyId,
              deptId: res.data.deptId,
              postId: res.data.postId,
              loginSource: this.adminUid.loginSource
            };
            localStorage.setItem('USERNAME', btoa(JSON.stringify(data)));
          }
          if (res.errorCode === config.ERR_SIGN_FIRST_RESET) {
            // 首次登录未修改密码
            this.$router.push({ name: 'first-reset' });
          } else if (res.errorCode === config.ERR_SIGN_TIME_OUT) {
            // 距离上次登陆超过72小时
            this.$router.push({ name: 'authentication' });
          } else {
            this.$showToast(res.errorMsg);
          }
        }
      });
    },
    ...mapMutations({
      setRoleList: 'SET_ROLE_LIST',
      setPostData: 'SET_POST_DATA',
      setUserInfo: 'SET_USER_INFO',
      setToken: 'SET_TOKEN',
      setOrgAndPostList: 'SET_ORG_AND_POST_LIST'
    })
  }
};
</script>

<style scoped></style>

<style lang="scss">
.role-drawer {
  .cube-drawer-main {
    left: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 12px 12px 0px 0px;
    transform: translate3d(0, 0, 0) !important;
  }
  .cube-drawer-panel + .cube-drawer-panel {
    margin-left: 0;
  }
  .cube-drawer-item {
    text-align: left;
    background-color: $color-FFF;
    &:last-child {
      &::after {
        display: block;
      }
    }
  }
  .cube-drawer-panel {
    box-shadow: none;
    .cube-drawer-item {
      > span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      }
    }
    &:first-child {
      background: #f7f7f7;
      border: none;
      .cube-drawer-item {
        height: 96px;
        margin: 0;
        padding: 0;
        // line-height: 22px;
      }
      .cube-drawer-item_active {
        color: $color-theme;
        background: #fff;
      }
      .cube-drawer-item {
        &::after {
          border-bottom: none;
        }
        > div {
          color: #999;
          font-size: 12px;
        }
        &.cube-drawer-item_active {
          > div {
            color: $color-theme;
          }
        }
      }
    }
    &:last-child {
      .cube-drawer-item_active {
        color: $color-theme;
        background: #fff;
      }
      .cube-drawer-item {
        display: flex;
        justify-content: space-between;
        > i {
          color: $color-theme;
          font-size: 12px;
        }
        > .iconfont {
          display: none;
        }
        .flag {
          display: none;
        }
        &.cube-drawer-item_active {
          > .iconfont {
            display: block;
          }
          .flag {
            display: inherit;
          }
        }
      }
    }
  }
  .nodata {
    height: 100%;
    padding: 17vh 0;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
  // crm配合公共组-岗位合并
  .post-box{
    width: 100%;
    .post-box-out {
      display: flex;
      justify-content: flex-start;
      margin: 14px 16px
    }
    .post-avatar {
      margin-right: 12px;
      .post-avatar-name{
        width: 40px;
        height: 40px;
        color: $color-theme;
        font-weight: 600;
        font-size: $font-12;
        line-height: 40px;
        text-align: center;
        background-color: #E8FBFF;
        border-radius: 50%;
      }
    }
    .post-info-box{
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;
      .post-info-title-box{
        display: flex;
        flex:1;
        justify-content: flex-start;
        line-height: 20px;
        .post-info-title {
          flex:1;
          margin-bottom: 8px;
          overflow: hidden;
          color: #23252E;
          font-weight: 600;
          font-size: 16px;
          text-overflow: ellipsis;
        }
        .post-current{
          width: 58px;
          height: 20px;
          color: $color-theme;
          font-size: 12px;
          text-align: center;
          border: 1px solid #00A4FF;
          border-radius: 2px;
        }
      }
      .post-info-position{
        flex:1;
        overflow: hidden;
        color: #999BA3;
        font-size: 14px;
        line-height: 18px;
        text-overflow: ellipsis;

      }
    }
  }
}
</style>
