<template>
  <cube-slide
    ref="slide"
    :data="slideList"
    :initial-index="initialIndex"
    :loop="loop"
    :auto-play="autoPlay"
    :interval="interval"
    :threshold="threshold"
    :speed="speed"
    :allow-vertical="true"
  >
    <cube-slide-item
      v-for="(item, index) in slideList"
      :key="index"
      class="slide-main"
      @click.native="clickPage(item, index)"
    >
      <slot :data="item" />
    </cube-slide-item>
    <template
      v-if="dotsSlot"
      slot="dots"
      slot-scope="props"
    >
      <span
        v-for="(item, index) in props.dots"
        :key="index"
        class="my-dot"
        :class="{active: props.current === index}"
      />
    </template>
  </cube-slide>
</template>

<script>
export default {
  props: {
    slideList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      initialIndex: 0,
      loop: false,
      autoPlay: false,
      interval: 3000,
      threshold: 0.3,
      speed: 400,
      allowVertical: false,
      dotsSlot: true
    };
  },
  methods: {
    clickPage(item, index) {
      console.log(item, index);
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-main{
  background-color: $color-FFF;
}

</style>
